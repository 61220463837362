
export const AppMenus = {
    
	navbarTopRightItems: [],
	navbarTopLeftItems: [],
	navbarSideLeftItems: [
  {
    "path": "/home",
    "label": "Home",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/billing",
    "label": "Billing",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/customer",
    "label": "Customer",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/owners",
    "label": "Owners",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/vehilcemaster",
    "label": "Vehilcemaster",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],
	BillingTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Customerid",
    "align": "left",
    "sortable": false,
    "name": "customerid",
    "field": "customerid"
  },
  {
    "label": "Bdate",
    "align": "left",
    "sortable": false,
    "name": "bdate",
    "field": "bdate"
  },
  {
    "label": "Remarks",
    "align": "left",
    "sortable": false,
    "name": "remarks",
    "field": "remarks"
  },
  {
    "label": "Text",
    "align": "left",
    "sortable": false,
    "name": "text",
    "field": "text"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	CustomerTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Customername",
    "align": "left",
    "sortable": false,
    "name": "customername",
    "field": "customername"
  },
  {
    "label": "Idnumber",
    "align": "left",
    "sortable": false,
    "name": "idnumber",
    "field": "idnumber"
  },
  {
    "label": "Moblienumber",
    "align": "left",
    "sortable": false,
    "name": "moblienumber",
    "field": "moblienumber"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	OwnersTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Ownersname",
    "align": "left",
    "sortable": false,
    "name": "ownersname",
    "field": "ownersname"
  },
  {
    "label": "Text",
    "align": "left",
    "sortable": false,
    "name": "text",
    "field": "text"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	VehilcemasterTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Vehiclename",
    "align": "left",
    "sortable": false,
    "name": "vehiclename",
    "field": "vehiclename"
  },
  {
    "label": "Model",
    "align": "left",
    "sortable": false,
    "name": "model",
    "field": "model"
  },
  {
    "label": "Vnumber",
    "align": "left",
    "sortable": false,
    "name": "vnumber",
    "field": "vnumber"
  },
  {
    "label": "Amountdaily",
    "align": "left",
    "sortable": false,
    "name": "amountdaily",
    "field": "amountdaily"
  },
  {
    "label": "Amountmonthly",
    "align": "left",
    "sortable": false,
    "name": "amountmonthly",
    "field": "amountmonthly"
  },
  {
    "label": "Insurance",
    "align": "left",
    "sortable": false,
    "name": "insurance",
    "field": "insurance"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: '',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}