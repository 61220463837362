
import { store } from 'quasar/wrappers'
import { createStore } from 'vuex';
import { app } from "./app.js"
import { billing } from "./billing.js"
import { customer } from "./customer.js"
import { owners } from "./owners.js"
import { vehilcemaster } from "./vehilcemaster.js"
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      app,
      billing,
		customer,
		owners,
		vehilcemaster
    },
    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
   // strict: process.env.DEBUGGING
  })
  return Store
})