
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

let routes = [{
	name: 'main',
	path: '/',
	component: () => import('layouts/MainLayout.vue'),
	children: [
		
		{ path: '/home',name: 'home', component: () => import('pages/home/home.vue'), props: true },//Dashboard routes


//billing routes
		{ path: '/billing', name: 'billinglist', component: () => import('pages/billing/list.vue'), props: true },
		{ path: '/billing/index/:fieldName?/:fieldValue?', component: () => import('pages/billing/list.vue'), props: true },
		{ path: '/billing/view/:id', name: 'billingview', component: () => import('pages/billing/view.vue'), props: true },
		{ path: '/billing/add', name: 'billingadd', component: () => import('pages/billing/add.vue'), props: true },
		{ path: '/billing/edit/:id', name: 'billingedit', component: () => import('pages/billing/edit.vue'), props: true },

//customer routes
		{ path: '/customer', name: 'customerlist', component: () => import('pages/customer/list.vue'), props: true },
		{ path: '/customer/index/:fieldName?/:fieldValue?', component: () => import('pages/customer/list.vue'), props: true },
		{ path: '/customer/view/:id', name: 'customerview', component: () => import('pages/customer/view.vue'), props: true },
		{ path: '/customer/add', name: 'customeradd', component: () => import('pages/customer/add.vue'), props: true },
		{ path: '/customer/edit/:id', name: 'customeredit', component: () => import('pages/customer/edit.vue'), props: true },

//owners routes
		{ path: '/owners', name: 'ownerslist', component: () => import('pages/owners/list.vue'), props: true },
		{ path: '/owners/index/:fieldName?/:fieldValue?', component: () => import('pages/owners/list.vue'), props: true },
		{ path: '/owners/view/:id', name: 'ownersview', component: () => import('pages/owners/view.vue'), props: true },
		{ path: '/owners/add', name: 'ownersadd', component: () => import('pages/owners/add.vue'), props: true },
		{ path: '/owners/edit/:id', name: 'ownersedit', component: () => import('pages/owners/edit.vue'), props: true },

//vehilcemaster routes
		{ path: '/vehilcemaster', name: 'vehilcemasterlist', component: () => import('pages/vehilcemaster/list.vue'), props: true },
		{ path: '/vehilcemaster/index/:fieldName?/:fieldValue?', component: () => import('pages/vehilcemaster/list.vue'), props: true },
		{ path: '/vehilcemaster/view/:id', name: 'vehilcemasterview', component: () => import('pages/vehilcemaster/view.vue'), props: true },
		{ path: '/vehilcemaster/add', name: 'vehilcemasteradd', component: () => import('pages/vehilcemaster/add.vue'), props: true },
		{ path: '/vehilcemaster/edit/:id', name: 'vehilcemasteredit', component: () => import('pages/vehilcemaster/edit.vue'), props: true },

		
		
		
		

//Error pages
		{ path:  '/error/forbidden', name: 'forbidden',  component: () => import('pages/errors/forbidden.vue') },
		{ path: '/error/notfound',  name: 'notfound', component: () => import('pages/errors/pagenotfound.vue') }
	],
	
	},
	{ path: '/:catchAll(.*)*', component: () => import('pages/errors/pagenotfound.vue') }
];

export default route(async function ({ store }) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)
	
	let mainRoute = routes.find(x => x.name = "main");
	

	const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })
  return Router
})




